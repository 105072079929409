* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	font-family: "Ubuntu", sans-serif;
}
